#checkout_form [name="checkout_form[paymentMethod]"] {
    display: none;
}

/*------------- #ALERTS ---------------*/
.alert-form-errors ul {
    margin-bottom: 0px;
}

.alert-form-errors ul li {
    list-style-type: disc;
    margin-bottom: 0px;
}

/*------------- #TEXTS ---------------*/
.fs-12 {
    font-size: 12px;
}

/*------------- #BUTTONS ---------------*/
.button-squared {
    border-radius: 5px;
}

.crumina-button:disabled {
    color: var(--dark-themes) !important;
}

/*------------- #FORMS ---------------*/
input:invalid, textarea:invalid, select:invalid {
    border: none;
}

.registration_form .invalid-feedback {
    color: var(--yellow-themes);
    padding-left: 5px;
    font-weight: normal;
}

/*------------- #TOASTR ---------------*/
#toast-container > div {
    position: relative;
    overflow: hidden;
    margin: 0 0 6px;
    padding: 15px 15px 15px 50px;
    width: 300px;
    -moz-border-radius: 3px 3px 3px 3px;
    -webkit-border-radius: 3px 3px 3px 3px;
    border-radius: 3px 3px 3px 3px;
    background-position: 15px center;
    background-repeat: no-repeat;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    color: #ffffff;
    opacity: 1;
    -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
    filter: alpha(opacity=100);
}

#toast-container > div:hover {
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    opacity: 1;
    -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
    filter: alpha(opacity=100);
    cursor: pointer;
}

/*------------- #TABS STYLE HOSTING START --------------*/
.tabs--style-login {
    .nav-tabs {
        display: flex;
        align-items: flex-end;
        flex-wrap: nowrap;
        margin-bottom: 10px;
    }

    .nav-tabs .nav-link {
        width: 100%;
        text-align: center;
        font-weight: 700;
        font-size: 18px;
        color: var(--dark-themes);
        border: none;
        border-bottom: 2px solid var(--dark-themes);
        padding: 15px;
    }

    .nav-tabs .nav-link.active {
        color: var(--orange-themes);
        background-color: transparent;
        border-color: transparent transparent var(--orange-themes) transparent;
    }

    .nav-tabs .nav-link:hover,
    .nav-tabs .nav-link:focus {
        color: var(--orange-themes);
    }
}

.tabs--style-hosting-start {
    .nav-tabs {
        display: flex;
        align-items: flex-end;
        flex-wrap: nowrap;
        margin-bottom: 10px;
    }

    .nav-tabs .nav-link {
        width: 100%;
        text-align: center;
        font-weight: 700;
        font-size: 18px;
        color: var(--dark-themes);
        border: none;
        border-bottom: 2px solid var(--dark-themes);
        padding: 15px;
    }

    .nav-tabs .nav-link.active {
        color: var(--orange-themes);
        background-color: transparent;
        border-color: transparent transparent var(--orange-themes) transparent;
    }

    .nav-tabs .nav-link:hover,
    .nav-tabs .nav-link:focus {
        color: var(--orange-themes);
    }
}

/*------------- #BUSINESS POLICIES IN FOOTER --------------*/

.business-policies-footer {
    ul {
        list-style: none;
        margin: 0;
        padding: 0;

        li {
            margin-bottom: 4px;
        }
    }
}

/*------------- #CUSTOMER ADDRESSES --------------*/

.post.selected-address {
    border-color: var(--yellow-themes);
}