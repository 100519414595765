/*------------- #THEME FONT --------------*/
/* quicksand-300 - latin-ext_latin */
@font-face {
  font-family: 'Quicksand';
  font-display: swap;
  font-style: normal;
  font-weight: 300;
  src: local("Quicksand Light"), local("Quicksand-Light"), url("../fonts/quicksand-v9-latin-ext_latin-300.woff2") format("woff2"), url("../fonts/quicksand-v9-latin-ext_latin-300.woff") format("woff");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */ }

/* quicksand-500 - latin-ext_latin */
@font-face {
  font-family: 'Quicksand';
  font-display: swap;
  font-style: normal;
  font-weight: 500;
  src: local("Quicksand Medium"), local("Quicksand-Medium"), url("../fonts/quicksand-v9-latin-ext_latin-500.woff2") format("woff2"), url("../fonts/quicksand-v9-latin-ext_latin-500.woff") format("woff");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */ }

/* quicksand-700 - latin-ext_latin */
@font-face {
  font-family: 'Quicksand';
  font-display: swap;
  font-style: normal;
  font-weight: 700;
  src: local("Quicksand Bold"), local("Quicksand-Bold"), url("../fonts/quicksand-v9-latin-ext_latin-700.woff2") format("woff2"), url("../fonts/quicksand-v9-latin-ext_latin-700.woff") format("woff");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */ }

/* quicksand-regular - latin-ext_latin */
@font-face {
  font-family: 'Quicksand';
  font-display: swap;
  font-style: normal;
  font-weight: 400;
  src: local("Quicksand Regular"), local("Quicksand-Regular"), url("../fonts/quicksand-v9-latin-ext_latin-regular.woff2") format("woff2"), url("../fonts/quicksand-v9-latin-ext_latin-regular.woff") format("woff");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */ }
